<template>
  <el-main>
    <div class="search">
      <div class="left">
        <span>隔离点工作人员</span>
      </div>
      <div class="right">
        <el-input v-model="keywords" placeholder="请输入搜索..."></el-input>
        <el-button type="primary" @click="getList">搜索</el-button>
        <el-button @click="add">添加</el-button>
        <el-button>导出</el-button>
        <el-button>导入</el-button>
      </div>
    </div>
    <el-table :data="list" row-key="id"
      ><el-table-column prop="id" label="编号" align="center"></el-table-column>
      <el-table-column
        prop="teacher"
        label="姓名"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="positioning"
        label="岗位"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="mobile"
        label="电话"
        align="center"
      ></el-table-column>
      <el-table-column prop="goods_name" label="性别" align="center">
        <template v-slot="{ row }">
          {{ row.sex ? "女" : "男" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="current_temperature"
        label="当前体温"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="maximum_temperature"
        label="最高体温"
        align="center"
      ></el-table-column>
      <el-table-column prop="age" label="年龄" align="center"></el-table-column>
      <el-table-column
        prop="address"
        label="现住址"
        align="center"
      ></el-table-column>
      <el-table-column prop="goods_name" label="上岗时间" align="center">
        <template v-slot="{ row }">
          <span v-if="row.teacher_income_time">{{
            getDateformat(row.teacher_income_time)
          }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="teacher_comefrom" label="出勤单位" align="center">
      </el-table-column>
      <el-table-column prop="remark" label="备注" align="center">
      </el-table-column>
      <el-table-column prop="goods_name" label="操作" align="center">
        <template v-slot="{ row }">
          <el-button size="small" @click="handleEdit(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-size="rows"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total_number"
    >
    </el-pagination>
  </el-main>
</template>

<script>
import { getDateformat } from "@/utils/getDate";
export default {
  data() {
    return {
      page: 1,
      rows: 10,
      keywords: "",
      list: [],
      total_number: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      let data = {
        page: this.page,
        rows: this.rows,
      };
      if (this.keywords) data.keywords = this.keywords;
      this.$axios.post(this.$api.teacher.list, data).then((res) => {
        if (res.code == 0) {
          let list = res.result.list;
          this.list = list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleSizeChange(val) {
      this.rows = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    handleEdit(row) {
      this.$router.push({
        path: "/home/AddLsolationPointMedicalStaff",
        query: {
          id: row.id,
        },
      });
    },
    add() {
      this.$router.push("/home/AddLsolationPointMedicalStaff");
    },
  },
};
</script>
<style lang="less" scoped>
.el-main {
  color: #333;
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .search {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .left {
      font-weight: bold;
      font-size: 18px;
      span:nth-child(2) {
        margin-left: 4px;
        color: #1e92ff;
      }
    }
    .right {
      .el-input {
        width: 270px;
        margin-right: 20px;
        /deep/ .el-input__inner {
          border-radius: 19px;
          margin-right: 20px;
        }
      }

      .el-button {
        border-radius: 19px;
      }
      .el-button--default {
        border-color: #409eff;
      }
    }
  }
  .el-table {
    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
    margin-bottom: 10px;
  }
}
</style>
